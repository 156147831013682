//The purpose of this is to handle special character input and remove all of them
//'use strict';
//angular.module("noSpecialChar", [])
    app.directive('noSpecialChar', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (inputValue) {
                    if (inputValue == null)
                        return ''
                    var cleanInputValue = inputValue.replace(/[^\w\s\-]/gi, '');
                    if (cleanInputValue != inputValue) {
                        modelCtrl.$setViewValue(cleanInputValue);
                        modelCtrl.$render();
                    }
                    return cleanInputValue;
                });
            }
        }
    });